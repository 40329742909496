.btn--primary,
.btn--secondary,
.btn--success,
.btn--negative {
  &:disabled,
  &:disabled:hover {
    outline: none;
    box-shadow: none;
  }

  border: 1px solid;
}

.btn--primary {
  border-color: var(--aux-blue);
}

.btn--success {
  border-color: var(--aux-green);
}

.btn--negative {
  border-color: var(--aux-red-dark);
}

.btn--blue:disabled:hover {
  background-color: var(--aux-blue);
}
