@use "@ag-grid-community/styles" as ag;

@mixin ag-cell-align($flex-justify, $text-align) {
  justify-content: $flex-justify !important;
  text-align: $text-align !important;
}

@mixin custom-option-with-click-handler($option-wrapper-class) {
  ::ng-deep .ng-option {
    .#{$option-wrapper-class} {
      padding: 10px 8px;
    }

     &:not(.ng-option-disabled:only-child) {
      padding: 0;
    }

    &.ng-option-disabled:only-child .#{$option-wrapper-class} {
      padding: 0;
    }
  }
}

@mixin custom-option-template {
  .ng-dropdown-panel-items {
    display: none !important;
  }

  .ng-dropdown-panel .ng-dropdown-footer {
    border-top: 0;
  }

  .items-wrapper {
    overflow-y: auto;
  }
}

@mixin ag-grid-theme {
  $gray-light: var(--aux-gray-light);
  $gray-dark: var(--aux-gray-dark);
  $black: var(--aux-black);
  $indent-size: 10px;
  $border-color: 'transparent';
  $horizontal-border: solid $gray-dark;

  @include ag.grid-styles(
    (
      theme: alpine,
      --ag-font-family: (
        'Inter',
        sans-serif,
      ),
      --ag-input-disabled-background-color: $gray-light,
      --ag-input-disabled-border-color: $gray-dark,
      --ag-secondary-border-color: $gray-dark,
      --ag-cell-horizontal-border: $horizontal-border,
      --ag-row-border-color: transparent,
      --ag-font-size: 14px,
      --ag-header-cell-hover-background-color: transparent,
      --ag-header-background-color: $gray-light,
      --ag-header-column-resize-handle-color: $gray-dark,
      --ag-data-color: #000,
      --ag-header-foreground-color: $black,
      --ag-secondary-foreground-color: $gray-light,
      --ag-row-group-indent-size: $indent-size,
      --ag-icon-font-family: agGridAlpine,
    )
  );

  .ag-row {
    border: none;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-cols-container,
  .ag-row-group-expanded,
  .ag-row-group-contracted {
    .ag-cell-value.ag-cell,
    .ag-row {
      display: block;
    }
  }
}


@mixin nullify-ag-grid-borders {
  .ag-root-wrapper,
  .ag-root,
  .ag-header,
  .ag-header-container,
  .ag-header-row,
  .ag-header-cell,
  .ag-header-group-cell {
    @apply border-0;
  }
}

@mixin override-ag-grid-borders($color) {
  @include nullify-ag-grid-borders();

  // Add borders to the table container
  .ag-root-wrapper {
    @apply border border-[color:var(--#{$color})];
  }

  // Add borders to header
  .ag-header {
    @apply border-b border-[color:var(--#{$color})];
  }

  // Add right border to cells except the last one
  .ag-header-cell:not(:last-child),
  .ag-header-cell.not-last-child,
  .ag-header-group-cell:not(:last-child) {
    @apply border-r border-[color:var(--#{$color})];
  }

  // Add bottom border to header rows except the last one
  .ag-header-row:not(:last-child) {
    @apply border-b border-[color:var(--#{$color})];
  }

  // Remove bottom border from header row column group
  .ag-header-row-column-group {
    @apply border-b-0 #{!important};
  }

  // Ensure consistent background color
  .ag-header-cell,
  .ag-header-group-cell {
    @apply bg-white;
  }

  // Maintain white background on hover
  .ag-header-cell:hover,
  .ag-header-group-cell:hover {
    @apply bg-white;
  }

  // Handle pinned left header
  .ag-pinned-left-header {
    @apply border-r border-[color:var(--#{$color})];
  }

  // Remove left border from the first column in the pinned left header
  .ag-pinned-left-header .ag-header-cell:first-child {
    @apply border-l-0;
  }

  // Remove doubled borders for sub-columns
  .ag-header-group-cell {
    @apply border-b-0;
  }

  // Ensure the last cell in a group doesn't have a right border
  .ag-header-group-cell:last-child .ag-header-cell:last-child:not(.not-last-child) {
    @apply border-r-0;
  }

  // Remove bottom border from the last header row
  .ag-header-row:last-child {
    @apply border-b-0;
  }

  .ag-header-group-cell-with-group,
  .ag-header-cell {
    @apply text-[var(--text)] border-t border-[color:var(--#{$color})];

    &:hover {
      @apply bg-white #{!important};
    }
  }

  .ag-center-cols-viewport,
  .ag-floating-bottom-container {
    .ag-cell:not(.ag-cell.ag-cell-range-left):not(.ag-cell-range-single-cell):not(.ag-cell-edit-mode),
    .ag-row-group > .ag-cell-expandable:not(.ag-cell.ag-cell-range-left):not(.ag-cell-range-single-cell):not(.ag-cell-edit-mode) {
      @apply border-l-0 #{!important};
    }
    .ag-cell:not(.border_right):not(.ag-cell-edit-mode):not(.ag-cell.ag-cell-range-right):not(.ag-cell-range-single-cell),
    .ag-row-group > .ag-cell-expandable:not(.border_right):not(.ag-cell-edit-mode):not(.ag-cell.ag-cell-range-right):not(.ag-cell-range-single-cell) {
      @apply border-r-0 #{!important};
    }

    .ag-cell:not(.border_right):not(.ag-cell-edit-mode):not(.ag-cell.ag-cell-range-right):not(.ag-cell-range-single-cell),
    .ag-row-group > .ag-cell-expandable:not(.border_right):not(.ag-cell-edit-mode):not(.ag-cell.ag-cell-range-right):not(.ag-cell-range-single-cell) {
      border-right: none !important;
    }
  }

  // half border after activities
  .ag-header-cell.ag-column-first:before {
    content: '';
    @apply block absolute border-t border-[color:var(--#{$color})];
  }

  .ag-cell, .ag-row-group > .ag-cell-expandable {
    @apply border-[0.5px] border-l-0 border-[color:var(--#{$color})] #{!important};
  }

  .ag-cell.ag-cell-not-edit-cell {
    @apply border-[color:var(--#{$color})] #{!important};

    opacity: unset;
  }
}

@mixin set-ag-grid-hover-row($category-row-color, $row-color) {
  .category-row:not(.ag-row-hover), .category-row.ag-row-focus {
    .ag-cell:not(.btn--negative:not([disabled]):active):not(
      .ag-cell-not-inline-editing.ag-cell-range-selected.ag-cell-range-single-cell
    ):not(.ag-cell.ag-cell-range-selected), .ag-cell-expandable:not(.bg-aux-error):not(.ag-cell-highlight):not(.has-cell-error):not(.removed-row):not(.new-cell) {
      @apply bg-[color:var(--#{$category-row-color})] #{!important};
    }
  }

  .ag-row.ag-row-hover.ag-row-focus:not(.category-row) {
    .ag-cell:not(.btn--negative:not([disabled]):active):not(
      .ag-cell-not-inline-editing.ag-cell-range-selected.ag-cell-range-single-cell
      ):not(.ag-cell.ag-cell-range-selected), .ag-cell-expandable:not(.bg-aux-error):not(.ag-cell-highlight):not(.has-cell-error):not(.removed-row):not(.new-cell) {
      @apply bg-[color:var(--#{$row-color})] #{!important};
    }
  }

  .ag-row:not(.category-row):not(.ag-row-hover),
  .ag-cell:not(.btn--negative:not([disabled]):active):not(.ag-cell.ag-cell-range-selected) {
    @apply bg-[color:var(--#{$row-color})] #{!important};
  }
}
@mixin tooltip {
  font-family: 'Inter', sans-serif;
  @apply bg-[#292929] text-white py-2 px-4 rounded-[4px] font-light;
}

