html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: var(--bg);
  color: var(--text);
  @apply transition-colors duration-200 ease-in-out;
}

button:disabled {
  cursor: not-allowed;
}
