/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 17, 2020 */


@font-face {
  font-family: 'config_condensedblack';
  src: url('configcondensed-black-webfont.woff2') format('woff2'),
  url('configcondensed-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedblack_italic';
  src: url('configcondensed-blackitalic-webfont.woff2') format('woff2'),
  url('configcondensed-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedbold';
  src: url('configcondensed-bold-webfont.woff2') format('woff2'),
  url('configcondensed-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedbold_italic';
  src: url('configcondensed-bolditalic-webfont.woff2') format('woff2'),
  url('configcondensed-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedextrabold';
  src: url('configcondensed-extrabold-webfont.woff2') format('woff2'),
  url('configcondensed-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedXBdIt';
  src: url('configcondensed-extrabolditalic-webfont.woff2') format('woff2'),
  url('configcondensed-extrabolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedextralight';
  src: url('configcondensed-extralight-webfont.woff2') format('woff2'),
  url('configcondensed-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedXLtIt';
  src: url('configcondensed-extralightitalic-webfont.woff2') format('woff2'),
  url('configcondensed-extralightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condenseditalic';
  src: url('configcondensed-italic-webfont.woff2') format('woff2'),
  url('configcondensed-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedlight';
  src: url('configcondensed-light-webfont.woff2') format('woff2'),
  url('configcondensed-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'config_condensedlight_italic';
  src: url('configcondensed-lightitalic-webfont.woff2') format('woff2'),
  url('configcondensed-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'config_condensedmedium';
  src: url('configcondensed-medium-webfont.woff2') format('woff2'),
  url('configcondensed-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
