@mixin btn_base($baseClasses, $hover, $active: '', $disabled: 'opacity-50 cursor-not-allowed') {
  @apply #{$baseClasses};
  &:not([disabled]) {
    @apply #{$hover};

    @if ($active != '') {
      &:active {
        @apply #{$active};
      }
    }
  }
  &:disabled {
    @apply #{$disabled};
  }
}

@layer components {
  .spinner {
    @apply ease-linear rounded-full border-4 border-t-4 border-gray-300 h-12 w-12 animate-spin;
    border-top-color: var(--aux-blue);
  }

  .aux-link,
  .aux-link .ag-group-value {
    @apply font-medium text-aux-blue underline;
  }

  .aux-link.aux-link--black {
    @apply text-aux-gray-darkest;
  }

  .aux-link-zero-hyphen .aux-link-zero-hyphen .ag-group-value {
    @apply font-medium text-aux-blue;
  }

  .btn {
    @apply inline-flex justify-center items-center px-4 py-2 border shadow-sm font-medium rounded focus:ring-2 focus:ring-offset-2 focus:ring-aux-blue-light focus:outline-none;
  }

  .btn-nr {
    @apply inline-flex justify-center items-center px-4 py-2 border shadow-sm font-medium rounded focus:outline-none;
  }

  .btn-ds {
    @apply inline-flex justify-center items-center px-[15px] py-[6px] font-medium rounded focus:outline-none;
  }

  .btn--blue {
    @apply border-transparent text-white bg-aux-blue hover:bg-aux-blue-light;
  }

  .aux-link:disabled,
  .btn--blue:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .btn--primary {
    @include btn_base(
      'text-base btn-ds btn--blue h-[40px]',
      'hover:bg-aux-blue-hover',
      'bg-aux-blue active:bg-aux-blue-active active:shadow-inner active:outline-none'
    );
  }

  .btn--secondary {
    @include btn_base(
      'text-base btn-ds text-aux-blue border border-aux-blue bg-white h-[40px]',
      'hover:bg-aux-blue-lightHover',
      'active:bg-aux-blue-active active:text-white active:shadow-inner active:outline-none'
    );
  }

  .btn--tertiary {
    @include btn_base(
      'text-base btn-ds text-aux-blue h-[40px]',
      'hover:text-aux-blue hover:bg-aux-blue-lightHover',
      'active:text-white active:bg-aux-blue-dark active:outline-none'
    );
  }

  .btn--negative {
    @include btn_base(
      'text-base btn-ds text-white bg-aux-red-dark h-[40px]',
      'hover:bg-aux-red-hover',
      'bg-aux-error active:bg-aux-red-active active:shadow-inner active:outline-none'
    );
  }

  .btn--success {
    @include btn_base(
      'text-base btn-ds text-white bg-aux-green h-[40px]',
      'hover:bg-aux-green-hover',
      'bg-aux-green active:bg-aux-green-active active:shadow-inner active:outline-none'
    );
  }

  .btn--hyperlink {
    @include btn_base(
      'text-base btn-ds text-aux-blue-link underline',
      'hover:text-aux-blue',
      'active:text-aux-blue-dark active:outline-none'
    );
  }

  .btn--white {
    @apply border-2 border-aux-blue text-aux-blue hover:bg-gray-50;
  }

  .input,
  .select {
    @apply block h-12 mt-1 w-full border-aux-gray-dark font-medium rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500;
  }

  .select {
    @apply h-auto;
  }

  input:disabled,
  select:disabled {
    @apply opacity-75 cursor-not-allowed shadow-none;
  }

  .select__big .ng-select-container {
    @apply h-12;
  }

  .input.is-invalid {
    @apply ring-1 ring-aux-error border-aux-error;
  }

  .select.is-invalid:not(.ng-select-disabled) .ng-select-container {
    @apply ring-1 ring-aux-error border-aux-error;
  }

  .label {
    @apply block font-medium text-lg;
  }

  [type='radio' i]:disabled:not(:checked) {
    @apply bg-gray-200 cursor-not-allowed;
  }

  .ng-select.custom-outline.ng-select-opened {
    @apply ring-aux-blue-light-200 ring-2;
    .ng-select-container {
      @apply border-aux-blue;
    }
  }
}
